<template>
  <div class="savePowerLine">
    <div class="inp-box mt20">
      <span class="inp-box-title">是否启动线路异常分析:</span>
      <i-switch v-model="form.enablePowerLineError" true-color="#1B5FA8" false-color="#f00" size="large">
        <span slot="open">开启</span>
        <span slot="close">禁止</span>
      </i-switch>
    </div>
    <div class="inp-box mt20">
      <span class="inp-box-title2">起始时间类型:</span>
      <Select v-model="form.powerLineStartFlag" style="width:300px">
        <Option :value="1">自然时间</Option>
        <Option :value="2">日出时间</Option>
        <Option :value="3">日落时间</Option>
      </Select>
    </div>
    <div class="inp-box mt20">
      <span class="inp-box-title2">{{form.powerLineStartFlag==1?'选择时间':'偏移时长'}}:</span>
      <template v-if="form.powerLineStartFlag==1">
        <TimePicker style="width:80px" v-model="min" format="HH:mm" placeholder="选择时间"></TimePicker>
      </template>
      <template v-else>
        <Input v-model="form.powerLineStartOffset" number type="number" style="width:300px">
        <span slot="append">分钟</span>
        </Input>
        <span class="height32 ml10 ">日出日落时间时，正数顺时偏移，负数逆时偏移，为0不偏移</span>
      </template>
    </div>
    <div class="inp-box mt20">
      <span class="inp-box-title2">结束时间类型:</span>
      <Select v-model="form.powerLineEndFlag" style="width:300px">
        <Option :value="1">自然时间</Option>
        <Option :value="2">日出时间</Option>
        <Option :value="3">日落时间</Option>
      </Select>
    </div>
    <div class="inp-box mt20">
      <span class="inp-box-title2">{{form.powerLineEndFlag==1?'选择时间':'偏移时长'}}:</span>
      <template v-if="form.powerLineEndFlag==1">
        <TimePicker style="width:80px" v-model="min2" format="HH:mm" placeholder="选择时间"></TimePicker>
      </template>
      <template v-else>
        <Input v-model="form.powerLineEndOffset" number type="number" style="width:300px">
        <span slot="append">分钟</span>
        </Input>
        <span class="height32 ml10 ">日出日落时间时，正数顺时偏移，负数逆时偏移，为0不偏移</span>
      </template>
    </div>
    <div class="inp-box mt20">
      <span class="inp-box-title2">线路异常分析间隔:</span>
      <Input v-model="form.powerLineInterval" number type="number" style="width:300px">
      <span slot="append">分钟</span>
      </Input>
    </div>
    
    <div class="inp-box mt20" >
      <span class="inp-box-title2">分析异常窗口时间:</span>
      <Input v-model="form.powerLineErrorWindowSize" number type="number" style="width:300px">
      <span slot="append">分钟</span>
      </Input>
    </div>
    <div class="inp-box mt20" >
      <span class="inp-box-title2">异常最小设备数量:</span>
      <Input v-model="form.powerLineErrorMinCount" number type="number" style="width:300px">
      </Input>
    </div>
    <div class="inp-box mt20" >
      <span class="inp-box-title2">异常最小设备数量占比:</span>
      <Input v-model="form.powerLineErrorMinRate" number type="number" style="width:300px">
      <span slot="append">%</span>
      </Input>
    </div>
    <div class="inp-box mt20" v-if="form.powerLineEndFlag!=1 || form.powerLineStartFlag!=1">
      <span class="inp-box-title2">经度坐标:</span>
      <Input v-model="form.powerLineLng" number type="number" style="width:300px" disabled>
      </Input>
      <span class="height32 ml10"><a href="javascript:void(0)" slot="extra" @click="setMapCenter" style="margin-right:10px">设置经纬度</a>修改此值会导致系统设置的项目中心坐标变化</span>
    </div>
    <div class="inp-box mt20" v-if="form.powerLineEndFlag!=1 || form.powerLineStartFlag!=1">
      <span class="inp-box-title2">纬度坐标:</span>
      <Input v-model="form.powerLineLat" number type="number" style="width:300px" disabled>
      </Input>
      <span class="height32 ml10">修改此值会导致系统设置的项目中心坐标变化</span>
    </div>
    <AuthButton opCode='sse' size="default" long type="primary" class="defaultBut" @click="saveSetting">保存配置</AuthButton>
    <ModalSelectMapPoint v-model="showMapModal" :custCenter="{ lat: form.powerLineLat, lng: form.powerLineLng}" @posSelected="posSelected" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
export default {
  name: 'SavePowerLine',
  components: {
    ModalSelectMapPoint
  },
  props: {
  },
  data() {
    return {
      min: '00:00',
      min2: '00:00',
      showMapModal:false,
      form: {
        "enablePowerLineError": true, //是否启动线路异常分析
        "powerLineStartFlag": 2, //起始时间标识：1、自然时间；2、日出时间；3、日落时间；
        "powerLineStartOffset": 10, //时间偏移标识：自然时间时，取当前时间的分钟数，即小时*60+分钟；日出日落时间时，正数顺时偏移，负数逆时偏移，为0不偏移
        "powerLineEndFlag": 3, //结束时间标识：同powerLineStartFlag
        "powerLineEndOffset": -10, //同powerLineStartOffset
        "powerLineInterval": 15, //线路异常分析间隔，单位分钟
        "powerLineLng": 114.05829921047837, //当有日出日落设置时，需要给定的GIS坐标经度，修改此值会导致系统设置的项目中心坐标变化
        "powerLineLat": 22.63056743737606, //当有日出日落设置时，需要给定的GIS坐标纬度，修改此值会导致系统设置的项目中心坐标变化
        "powerLineErrorWindowSize": 5, //分析异常窗口时间，单位分钟
        "powerLineErrorMinCount": 5, //识别为异常的最小设备数量
        "powerLineErrorMinRate": 10, //识别为异常的最小设备数量占比
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize() {
    },
    'form.powerLineStartFlag'() {
      if (this.form.powerLineStartFlag != 1) {
        this.form.powerLineStartOffset = 0
      }
    },
    'form.powerLineEndFlag'() {
      if (this.form.powerLineEndFlag != 1) {
        this.form.powerLineEndOffset = 0
      }
    }
  },
  mounted: function () {
    this.getList();
  },
  destroyed: function () {

  },
  methods: {
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryPowerLine`, {}).then(res => {
        if (res.code == 0) {
          this.form = res.data
          if (this.form.powerLineStartFlag == 1) {
            let a = Math.floor(this.form.powerLineStartOffset / 60);
            let b = Math.floor(this.form.powerLineStartOffset % 60);
            this.min = a + ':' + b;
          }
          if (this.form.powerLineEndFlag == 1) {
            let a = Math.floor(this.form.powerLineEndOffset / 60);
            let b = Math.floor(this.form.powerLineEndOffset % 60);
            this.min2 = a + ':' + b;
          }
        }
      });
    },
    setMapCenter(){
      this.showMapModal = true;
    },
    posSelected(el){
      this.form.powerLineLng=el.lng;
      this.form.powerLineLat=el.lat;
    },
    saveSetting: function () {
      this.loading = true;
      if (this.form.powerLineStartFlag == 1) {
        let arr = this.min.split(":");
        this.form.powerLineStartOffset = arr[0] * 60 + arr[1] * 1;
      }
      if (this.form.powerLineEndFlag == 1) {
        let arr = this.min2.split(":");
        this.form.powerLineEndOffset = arr[0] * 60 + arr[1] * 1;
      }
      this.$axios.post(`//${this.domains.trans}/sys/setting/SavePowerLine`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$Message.info('保存成功')
        }
      });
    },
  }
}
</script>
<style scoped>
.savePowerLine {
  margin-left: 60px;
  margin-top: 30px;
}
.inp-box {
  display: flex;
}
.inp-box .inp-box-title {
  width: 150px;
  text-align: right;
  margin-right: 15px;
}
.inp-box .inp-box-title2 {
  width: 150px;
  text-align: right;
  line-height: 32px;
  margin-right: 15px;
}
.height32 {
  line-height: 32px;
}
.defaultBut {
  width: 300px;
  margin: 30px auto 0;
}
</style>